@font-face {
    font-family: "Montserrat";
    src: local("Montserrat"),
    url("./fonts/montserrat/Montserrat-Regular.woff") format("woff");
    font-weight: normal;
}
@font-face {
    font-family: "Montserrat";
    src: local("Montserrat"),
    url("./fonts/montserrat/Montserrat-Medium.woff") format("woff");
    font-weight: bold;
}

svg.recharts-surface tspan {
    font-family: 'Montserrat'
}

html {
    height: 100%;
    width: 100%;
}

body, #root, .root-stack {
    min-height: 100vh;
    width: 100%;
}

* {
    margin: 0;
    padding: 0;
}

.footer {
    position: fixed;
    width: 100%;
    bottom: 0;
}

.grecaptcha-badge {
    bottom: 100px !important;
}

.grecaptcha-badge-hide {
    visibility: hidden;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.calendar-availability {
    font-family: 'Montserrat'
}